import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import HallOfGods from "../components/games/hallofgods";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hall of Gods`}</h1>
    <p>{`Hall of Gods är en video slot som är skapade med ett tema kring den nordiska mytologin. Det är ett väldigt väl designat spel som har bra med bonus rundor och freespins möjligheter, något som är extra spännande är wild symbolen som har symbolen av en drake, när man får den på en av hjulen så expandera den sig ut över hela hjulet och kompletta alla vinnande spelrader.`}</p>
    <HallOfGods mdxType="HallOfGods" />
    <h2>{`Mega stor Jackpot`}</h2>
    <p>{`Just nu så har Hall of Gods en enormt stor jackpot som i skrivande stund överstiger 67 miljoner SEK , skulle den gå ut idag så skulle den bli världens näst största online jackpot att gå ut. Världsrekordet för världens största jackpot är på 109 miljoner kronor på casino spelet Mega Fortune, det var en tursam norrman som lyckades vinna jackpoten på den helt otroliga summan och för att göra det hela mera speciellt så satsade han inte en krona för att vinna den då han vann den igenom spela ett par free spins som han hade fått gratis av Betsson.`}</p>
    <h2>{`Hall of Gods bonus spel`}</h2>
    <p>{`För att komma in i bonus spelet där man har chans att vinna jackpoten så behöver du först få tre bonus symboler med Tors hammare Mjölner på skärmen. När du väl har kommit in i bonus spelet så ska du styra Tors hammare Mjölner och slå sönder sköldar som alla döljer ett hemligt värde av en jackpot eller summa. När du sedan har fått tre valörer eller jackpotar av samma värde så vinner du värdet på valören eller värdet av jackpoten.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      